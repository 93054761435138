#trade-container {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#trade-container #left{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#trade-container #right{
    width: 35%;
}

@media (max-width: 768px) {
    #trade-container {
        display: block;
    }
    #trade-container #left{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #trade-container #right{
        margin-top: 20px;
        width: 100%;
    }
}